import React, { useEffect, useState } from 'react';
import './style.scss';

import Lottie from 'lottie-react';
import LoaderCar from '../../assets/images/Loader.json';

function Loader() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Função para verificar se está no mobile
    const checkIfMobile = () => {
      const mobileCheck = window.innerWidth <= 768;
      setIsMobile(mobileCheck);
    };

    checkIfMobile(); // Verifica ao carregar a página

    window.addEventListener('resize', checkIfMobile); // Verifica ao redimensionar a tela

    return () => {
      window.removeEventListener('resize', checkIfMobile); // Limpa o event listener ao desmontar o componente
    };
  }, []);

  return (
    <div id="loading">
      <div id="loading-center" style={{ display: 'flex', justifyContent: 'center' }}>
        <Lottie animationData={LoaderCar} style={{ width: isMobile ? '50%' : '25%' }}/>
      </div>
    </div>
  );
}

export default Loader;
